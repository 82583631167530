<template>
    <div>
        <rs-form ref="form" :model="form" label-width="140px" style="width:70%;"  :show-message=false >
            <rs-form-item label="ID：" class="form-item"
                          prop="id"
                          :rules="[
                                    { required: true, message: '', trigger: 'blur' },
                                  ]">
                <span v-if="type == 'detail'">{{form.id}}</span>
                <rs-input v-else v-model="form.id"></rs-input>
            </rs-form-item>
            <rs-form-item label="名称：" class="form-item"
                          prop="title"
                          :rules="[
                                    { required: true, message: '', trigger: 'blur' },
                                  ]">
                <span v-if="type == 'detail'">{{form.title}}</span>
                <rs-input v-else v-model="form.title"></rs-input>
            </rs-form-item>

            <rs-form-item label="SQL语句：" class="form-item"
                          prop="sqlQuery"
                          :rules="[
                                    { required: true, message: '', trigger: 'blur' },
                                  ]">
                <span v-if="type == 'detail'">{{form.sqlQuery}}</span>
                <rs-input type="textarea" v-else v-model="form.sqlQuery"></rs-input>
            </rs-form-item>
            <rs-form-item label="SQL参数：" class="form-item"
                          prop="sqlParams">
                <span >{{form.sqlParams}}</span>
            </rs-form-item>
            <rs-form-item label="报表类型：" class="form-item"
                          prop="reportType"
                          :rules="[
                                    { required: true, message: '', trigger: 'blur' },
                                  ]">
                <span v-if="type == 'detail'">{{form.reportType}}</span>
                <rs-select v-else v-model="form.reportType">
                    <rs-option
                            v-for="item in list"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </rs-option>
                </rs-select>
            </rs-form-item>
            <rs-form-item label="x轴：" class="form-item"
                          prop="xAxis">
                <span v-if="type == 'detail'">{{form.xAxis}}</span>
                <rs-input v-else v-model="form.xAxis"></rs-input>
            </rs-form-item>
            <rs-form-item label="y轴：" class="form-item"
                          prop="yAxis">
                <span v-if="type == 'detail'">{{form.yAxis}}</span>
                <rs-input  v-else v-model="form.yAxis"></rs-input>
            </rs-form-item>


        </rs-form>
        <rs-create-button v-if="type != 'detail'" :buttonList="buttonList" @cancel="cancel" @save="save"></rs-create-button>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    export default {
        name: "detail.vue",
        props:['form','type'],
        data() {
            return {
                buttonList: [
                {
                    name: '返回',
                    function: 'cancel'
                },
                {
                    name: '保存',
                    type: 'primary',
                    function: 'save'
                }],
                list:[{
                    label:'卡片',
                    value:'card'
                },{
                    label:'列表',
                    value:'list'
                },{
                    label:'表单',
                    value:'table'
                },{
                    label:'柱状图',
                    value:'bar'
                },{
                    label:'饼图',
                    value:'pie'
                },{
                    label:'折线图',
                    value:'line'
                }]
            }
        },
        methods:{
            save(){
                console.log(this.type)
                if(this.type == 'create'){
                    Api.create({...this.form}).then(res => {
                        if(res.data.code == 200){
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.cancel()
                        }
                    })
                } else if(this.type == 'edit'){
                    Api.update({...this.form}).then(res => {
                        if(res.data.code == 200){
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.cancel()
                        }
                    })
                }
            },
            cancel(){
                this.$emit("cancel")
            }
        }
    }
</script>

<style scoped>

</style>