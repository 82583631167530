<template>
  <div class="main_block">
    <renderTable class="marginBottom" :list="tableData" :stripe="false" :columns="tableColumns" @row-dblclick="onRowClick">
      <div slot="action">
        <rs-button size="small" class="marginBottom" type="primary" @click="createForm">新增</rs-button>
      </div>
    </renderTable>

    <div class="pagination-wrap">
      <rs-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        background
        layout="prev, pager, next, sizes, jumper"
        :total="total"
      ></rs-pagination>
    </div>
    <rs-dialog :visible.sync="dialogVisible" :fullscreen="true" :before-close="cancel">
      <template slot="title" class="title_block">
        <span class="title">{{ title }}</span>
        <rs-button style="margin-left:14px;" size="mini" v-if="type == 'detail'" @click="editForm" type="primary">编辑</rs-button>
      </template>
      <Detail @cancel="cancel" :form="form" :type="type"></Detail>
    </rs-dialog>
  </div>
</template>

<script>
import Api from '@/api/reportServer'
import Detail from '@/views/reportServer/detail.vue'
import Utils from '@/utils/utils'
export default {
  name: 'list',
  components: { Detail },
  data() {
    return {
      ruleForm: [],
      tableData: [],
      tableColumns: [],
      currentPage: 1,
      total: 0,
      pageSize: 10,
      dialogVisible: false,
      title: '新增报表',
      flowClassifyOptions: '',
      formData: {},
      type: '', //detail、create、edit
      form: {}
    }
  },
  created() {
    this.renderPage()
  },
  methods: {
    onRowClick(row) {
      console.log(row)
      this.type = 'detail'
      this.title = '编辑类型'
      this.form = row
      this.dialogVisible = true
    },
    handleCurrentChange(val) {
      this.currentPage = val
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      this.renderPage(params)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.renderPage()
    },
    renderPage(params) {
      if (!params) {
        params = {
          pageSize: this.pageSize,
          pageNum: this.currentPage
        }
      }
      Api.getList(params).then(res => {
        this.tableData = res.data.data
        this.currentPage = res.data.data.pageNum
        this.total = res.data.data.total
      })
      this.tableColumns = [
        {
          type: 'index',
          label: '序号',
          width: '70',
          align: 'left'
        },
        {
          prop: 'id',
          label: 'ID'
        },
        {
          prop: 'title',
          label: '名称'
        },
        {
          prop: 'sqlQuery',
          label: 'SQL语句'
        }
      ]
    },
    editForm() {
      this.type = 'edit'
    },
    cancel() {
      this.dialogVisible = false
      this.renderPage()
    },
    createForm() {
      this.type = 'create'
      this.title = '新增类型'
      this.form = {}
      this.dialogVisible = true
    }
  },
  beforeRouteEnter(to, from, next) {
    if (Utils.getLocalStorage('userInfo')) {
      next()
    } else {
      next('/login')
    }
  }
}
</script>

<style scoped>
.main_block {
  margin-top: 20px;
}
.title {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 22px;
}
</style>
