import Http from '../utils/axios'
const rt = '/api/report'
const user = '/api/user'
const workflow = '/api/common/workflow'
const investment = '/api/investment'
export default {
    //获取列表
    getList(params) {
        return Http.post(`${rt}/reportConfig/list`, params)
    },
    create(params) {
        return Http.post(`${rt}/reportConfig/create`, params)
    },
    update(params) {
        return Http.post(`${rt}/reportConfig/update`, params)
    },
    getMall(params) {
        return Http.get(`${user}/v1/e/mall/getMallAuthoriHomePage`, params)
    },
    getReportData(id, params) {
        return Http.post(`${rt}/report/${id}`, params)
    },
    getworkflowToMe(params) {
        return Http.post(`${workflow}/e/flow/auditCenter/findFlowsRelatedToMe`, params)
    },
    getBuilding(params) {
        return Http.get(`${investment}/e/mall/queryInvestMallDetailByCode`, params)
    },
    reportConfigList(params) { //新增项目报表列表
        return Http.get(`${rt}/custom/report/config/list`, params);
    },
    reportConfigSave(params) { //新增项目报表配置
        return Http.post(`${rt}/custom/report/config/save`, params);
    },
    reportConfigDetail(params) { //报表配置详情
        return Http.get(`${rt}/custom/report/config/detail`, params);
    },
    reportConfigUpdate(params) { //编辑项目报表配置
        return Http.post(`${rt}/custom/report/config/update`, params);
    },
    reportConfigDefault(params) { //恢复默认配置
        return Http.get(`${rt}/custom/report/config/default`, params);
    }
}