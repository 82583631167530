var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main_block" },
    [
      _c(
        "renderTable",
        {
          staticClass: "marginBottom",
          attrs: {
            list: _vm.tableData,
            stripe: false,
            columns: _vm.tableColumns,
          },
          on: { "row-dblclick": _vm.onRowClick },
        },
        [
          _c(
            "div",
            { attrs: { slot: "action" }, slot: "action" },
            [
              _c(
                "rs-button",
                {
                  staticClass: "marginBottom",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.createForm },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "pagination-wrap" },
        [
          _c("rs-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.pageSize,
              background: "",
              layout: "prev, pager, next, sizes, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "rs-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            fullscreen: true,
            "before-close": _vm.cancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "template",
            { staticClass: "title_block", slot: "title" },
            [
              _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _vm.type == "detail"
                ? _c(
                    "rs-button",
                    {
                      staticStyle: { "margin-left": "14px" },
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.editForm },
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("Detail", {
            attrs: { form: _vm.form, type: _vm.type },
            on: { cancel: _vm.cancel },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }