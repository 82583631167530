var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "rs-form",
        {
          ref: "form",
          staticStyle: { width: "70%" },
          attrs: {
            model: _vm.form,
            "label-width": "140px",
            "show-message": false,
          },
        },
        [
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: {
                label: "ID：",
                prop: "id",
                rules: [{ required: true, message: "", trigger: "blur" }],
              },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.id))])
                : _c("rs-input", {
                    model: {
                      value: _vm.form.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "id", $$v)
                      },
                      expression: "form.id",
                    },
                  }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: {
                label: "名称：",
                prop: "title",
                rules: [{ required: true, message: "", trigger: "blur" }],
              },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.title))])
                : _c("rs-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: {
                label: "SQL语句：",
                prop: "sqlQuery",
                rules: [{ required: true, message: "", trigger: "blur" }],
              },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.sqlQuery))])
                : _c("rs-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.sqlQuery,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sqlQuery", $$v)
                      },
                      expression: "form.sqlQuery",
                    },
                  }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "SQL参数：", prop: "sqlParams" },
            },
            [_c("span", [_vm._v(_vm._s(_vm.form.sqlParams))])]
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: {
                label: "报表类型：",
                prop: "reportType",
                rules: [{ required: true, message: "", trigger: "blur" }],
              },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.reportType))])
                : _c(
                    "rs-select",
                    {
                      model: {
                        value: _vm.form.reportType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reportType", $$v)
                        },
                        expression: "form.reportType",
                      },
                    },
                    _vm._l(_vm.list, function (item) {
                      return _c("rs-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "x轴：", prop: "xAxis" },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.xAxis))])
                : _c("rs-input", {
                    model: {
                      value: _vm.form.xAxis,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "xAxis", $$v)
                      },
                      expression: "form.xAxis",
                    },
                  }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "y轴：", prop: "yAxis" },
            },
            [
              _vm.type == "detail"
                ? _c("span", [_vm._v(_vm._s(_vm.form.yAxis))])
                : _c("rs-input", {
                    model: {
                      value: _vm.form.yAxis,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "yAxis", $$v)
                      },
                      expression: "form.yAxis",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.type != "detail"
        ? _c("rs-create-button", {
            attrs: { buttonList: _vm.buttonList },
            on: { cancel: _vm.cancel, save: _vm.save },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }